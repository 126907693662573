<template>
	<header>
		<div class="header_content">
			<img height="100" src="../assets/logo.png" width="100"/>

			<div style="font-size: 28px; letter-spacing: 3px">公司主页</div>
		</div>
	</header>
	<section class="main">

		<div class="content">
			<h3>公司简介</h3>
			<p>杭州我爱号码网电子商务有限公司（简称“我爱号码网”）成立于2010年，注册资本1000万元，是一家专业通信产品互联网销售公司。</p>

			<p>杭州我爱号码网电子商务有限公司围绕运营商号卡业务为中心，解决客户刚需为主线，致力打造全国最为完善的通信号码及套餐定制平台。</p>

			<p>为了丰富号源及全国性的个性套餐，前期开放B端的互换资源的平台。此平台以全国300多家天猫通信店、京东通信店、拼多多通信店、个体专属的门户网站等做通信业务的商家为目标群体，共享号源和个性定制套餐。
				为了配合平台发展，我司目前拥有多个自营的天猫通信店、京东、拼多多店铺。已经合作B端商家有1000余家，欢迎喜欢号码的小伙伴们加入合作。</p>

			<p>
				拼多多店铺：<a href="http://mobile.yangkeduo.com/mall_page.html?mall_id=571878373" style="color: #ff4c4c" target="_blank">我爱号码网通信专营店</a>
			</p>

		</div>
		<div class="content">
			<h3>联系我们</h3>
			<p>公司地址：浙江省杭州市拱墅区石桥路293号7楼706室</p>
			<p>联系电话：0571-28888520</p>
		</div>

		<div class="content">
			<h3>微信公众号</h3>
			<p>
				<img height="200" src="../assets/gzh.jpg" width="200"/>
			</p>

		</div>

		<div class="content">
			<h3>微信小程序</h3>
			<p>
				<img src="../assets/wechat_mini.jpg" width="200" height="200"/>
			</p>
		</div>

		<div class="content">
			<p>
				<img src="../assets/unicom_1.jpg" width="600"/>
			</p>
		</div>
		<div class="content">
			<p>
				<img src="../assets/bsp.png" width="600"/>
			</p>
		</div>
		<div class="content">
			<p>
				<img src="../assets/unicom_2.png" width="600"/>
			</p>
		</div>
		<div class="content">
			<p>
				<img src="../assets/unicom_3.png" width="600"/>
			</p>
		</div>
	</section>

	<footer>
		<div>公司名称：杭州我爱号码网电子商务有限公司</div>
		<div>公司地址：浙江省杭州市拱墅区石桥路293号706室</div>
		<div>联系电话：0571-28888520</div>
		<div>
			<a href="http://www.beian.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
				target="_blank">
				<img src="../assets/gaba.png" style="float:left;">
				<span>浙公网安备 33010502006968号</span>
			</a>
		</div>
		<div>
			<a href="https://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
				target="_blank">浙ICP备19034816号-1</a>
		</div>
	</footer>
</template>

<script>

</script>

<style scoped>
footer {
	width: 100%;
	min-width: 1000px;
	background: #2B2B2B;
	color: #ffffff !important;
	padding: 20px 0px;
}

footer div {
	width: 100%;
	text-align: center;
	height: 35px;
	line-height: 35px;
	font-size: 18px;
}

a {
	text-decoration: none;
	color: #ffffff;
}

header {
	width: 100%;
	min-width: 1000px;
	height: 100px;
	line-height: 100px;
	background: #f4f7f9;

}

.header_content {
	width: 1000px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	color: #323b43;
}

.main {
	width: 100%;
}

.content {
	width: 1000px;
	margin: 0 auto;
	margin-top: 30px;
	font-size: 18px;
	color: #323b43;
}

.content p {
	text-indent: 30px;
	letter-spacing: 2px;
	margin: 10px;
	line-height: 35px;
}
</style>